/** @jsx jsx */
import { jsx, Box, Styled, Input, Label } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button } from '../components';
import Layout from '../components/LoginLayout';
import { getTranslate } from 'react-localize-redux';
import { navigate } from 'gatsby';
import * as api from '../utils/api';
import { showNotification, SEVERITY, hideAllNotifications } from '../state/notifications';
import getLanguage from '../utils/getLanguage';
import * as analytics from '../utils/analytics';
import Recaptcha from '../components/Recaptcha';
import { useRef } from 'react';

const RequestPasswordResetPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const translate = getTranslate(useSelector(state => state.localize));
  const [username, setUsername] = useState('');
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const recaptchaInstance = useRef(null);

  const reset = async (username, language) => {
    if (!username || username.length < 1) {
      dispatch(showNotification('resetPassword.message.resetPasswordMissingDetails'));
      return;
    }
    dispatch(hideAllNotifications());
    try {
      const token = await recaptchaInstance.current.executeAsync();
      setTimeout(() => recaptchaInstance.current.reset(), 250);
      await api.requestResetPassword(username, language, token);
      setUsername('');
      dispatch(showNotification('resetPassword.message.requestSuccess', SEVERITY.INFO));
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (err) {
      console.log('Resetting password failed', err);
      dispatch(showNotification('resetPassword.message.resetPasswordFailed'));
    }
  };

  return (
    <Layout title={translate('page.resetPassword')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Styled.h1 sx={{ mt: 0 }}>{translate('page.resetPassword')}</Styled.h1>
      <Styled.p>{translate('resetPassword.text')}</Styled.p>
      <Box sx={{ mb: 3 }}>
        <Label>{translate('resetPassword.usernameLabel')}</Label>
        <Input value={username} onChange={e => setUsername(e.target.value)} />
      </Box>
      <Button
        onClick={e => {
          e.preventDefault();
          reset(username, language);
        }}
      >
        {translate('resetPassword.resetButton')}
      </Button>
      <Recaptcha recaptchaInstance={recaptchaInstance} show={true} />
    </Layout>
  );
};

export default RequestPasswordResetPage;
